<template>
    <div>
        <ByBreads>忘記密碼</ByBreads>
        <el-row>
            <el-col :lg="8" :xs="0">
                &nbsp;
            </el-col>
            <el-col :lg="8" :md="6" :xs="24">
                <el-card style="margin: 10px;">
                    <el-form :model="findPwdRuleForm" status-icon :rules="rulesFindPwd" ref="findPwdForm"
                             label-position="top" label-width="80px" class="demo-findPwdRuleForm"
                             @keyup.enter.native="sendMail">
                        <el-form-item>
                            忘記您的密嗎？請輸入您註冊的電子郵件。您將會在電子郵件信箱中收到新密碼或重設密碼的連結。
                        </el-form-item>
                        <el-form-item label="電子郵件" prop="account">
                            <el-input v-model="findPwdRuleForm.account"
                                      placeholder="請輸入電子郵件"
                                      tabindex="1"
                                      autocomplete="on"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="code" class="captcha">
                            <div class="captcha">
                                <el-input
                                        v-model="findPwdRuleForm.code"
                                        style="width: 218px;"
                                        prefix-icon="el-icon-message"
                                        placeholder="验证碼"
                                        type="text"
                                        tabindex="2"
                                        autocomplete="off"
                                />
                                <div class="imgs" @click="getCaptcha()"><img :src="captchatImg"></div>
                            </div>
                        </el-form-item>

                        <el-form-item >
                            <el-button style="width: 100%;"  type="danger" @click="sendMail" :loading="loading">重設密碼</el-button>
                        </el-form-item>

                    </el-form>
                </el-card>
            </el-col>
            <el-col :lg="8" :xs="0">
                &nbsp;
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {UserLostPassword,getCaptcha} from '@/request/api';
    import ByBreads from '@/components/MyBreads.vue';

    export default {
        name: 'LostPassword',
        components: {
            ByBreads
        },
        data() {
            var checkAccount = (rule, value, callback) => {
                if (value =='' || !value) {
                    return callback(new Error('請輸入電子郵件'));
                }
                setTimeout(() => {
                    if (!/^\w+@\w+\.\w+$/.test(value)) {
                        callback(new Error('請輸入正確的電子郵件'));
                    } else {
                        callback();
                    }
                }, 1000);
            };

            return {
                loading: false,
                captchatImg:'',
                findPwdRuleForm: {
                    account: '',
                    code:'',
                },
                rulesFindPwd: {
                    account: [{required: true, type: 'email', validator: checkAccount, trigger: 'blur'}],
                    code: [{required: true, message: '請輸入正確的验证碼', trigger: 'blur'}]
                }
            }
        },
        mounted() {
            this.getCaptcha();
        },
        methods: {
            getCaptcha() {
                getCaptcha().then(res => {
                    this.captchatImg = res.data.code;
                    this.findPwdRuleForm.key = res.data.key;
                }).catch(({message}) => {
                    this.$message.error(message);
                })

            },
            submitForm() {

            },
            resetForm() {

            },
            sendMail() {
                let _this = this;
                _this.loading = true;
                this.$refs['findPwdForm'].validate((valid) => {
                    if (!valid) {
                        _this.$message.error('請輸入正確的電子郵件');
                        this.getCaptcha();
                        _this.loading = false;
                        return false;
                    }
                    UserLostPassword(_this.findPwdRuleForm).then(res => {
                        if (res.code == 200) {
                            _this.$message.success("已發送重置密碼連接至您的郵箱中，請您及時登入信箱重新設定！");
                            this.$router.push('/login');
                        }else{
                            _this.$message.error(res.message);
                            _this.getCaptcha();
                        }
                        _this.loading = false;
                    }).catch(err => {
                        _this.loading = false;
                        _this.$message.error(err);
                        _this.getCaptcha();
                    });
                });
            },
        }
    }
</script>
<style lang="less" scoped>
    .captcha {
        display: flex;
        align-items: flex-start;
    }
</style>
